<template>
    <li class="misc-slider-item">
        <slot></slot>
    </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MicroSliderItem',
})
</script>

<style lang="postcss">
.misc-slider-item:not(:last-of-type) {
    @apply pr-5;
}
.grid-variant .misc-slider-item:not(:last-of-type) {
    @apply p-0;
}
</style>
