<template>
    <div ref="container" class="misc-slider-component">
        <ul
            ref="scrollArea"
            class="misc-slider-component__scroll-area"
            :class="{ 'grid-variant': gridVariant }"
        >
            <slot></slot>
        </ul>
        <button
            :class="{ 'grid-variant': gridVariant }"
            ref="navigationLeft"
            class="misc-slider-component__navigation left hide"
        >
            <
        </button>
        <button
            :class="{ 'grid-variant': gridVariant }"
            ref="navigationRight"
            class="misc-slider-component__navigation right"
        >
            >
        </button>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'MicroSlider',
    props: {
        gridVariant: Boolean,
    },
    mounted() {
        if (!this.gridVariant || (this.gridVariant && !this.$bp.isDesktop)) {
            const scrollArea = this.$refs.scrollArea as HTMLDivElement
            const navigationLeft = this.$refs.navigationLeft as HTMLButtonElement
            const navigationRight = this.$refs.navigationRight as HTMLButtonElement

            if (scrollArea?.scrollWidth === scrollArea?.clientWidth) {
                navigationLeft?.classList.add('hide')
                navigationRight?.classList.add('hide')
            }

            window?.addEventListener('resize', () => {
                if (scrollArea?.scrollWidth === scrollArea?.clientWidth) {
                    navigationLeft?.classList.add('hide')
                    navigationRight?.classList.add('hide')
                } else {
                    if (scrollArea.scrollLeft > 0) {
                        if (navigationLeft?.classList.contains('hide')) {
                            navigationLeft?.classList.remove('hide')
                        }
                    } else {
                        navigationLeft?.classList.add('hide')
                    }

                    if (scrollArea.scrollWidth - (scrollArea.scrollLeft + scrollArea.clientWidth) > 0) {
                        if (navigationRight?.classList.contains('hide')) {
                            navigationRight?.classList.remove('hide')
                        }
                    } else {
                        navigationRight?.classList.add('hide')
                    }
                }
            })

            scrollArea?.addEventListener('scroll', () => {
                if (scrollArea.scrollLeft > 0) {
                    if (navigationLeft?.classList.contains('hide')) {
                        navigationLeft?.classList.remove('hide')
                    }
                } else {
                    navigationLeft?.classList.add('hide')
                }

                if (scrollArea.scrollWidth - (scrollArea.scrollLeft + scrollArea.clientWidth) > 0) {
                    if (navigationRight?.classList.contains('hide')) {
                        navigationRight?.classList.remove('hide')
                    }
                } else {
                    navigationRight?.classList.add('hide')
                }
            })

            navigationLeft?.addEventListener('click', () => {
                if (scrollArea && scrollArea.scrollLeft > 0) {
                    if (scrollArea.scrollLeft > scrollArea?.clientWidth) {
                        scrollArea.scrollLeft = scrollArea.scrollLeft - scrollArea?.clientWidth
                    } else {
                        scrollArea.scrollLeft = 0
                    }
                }
            })
            navigationRight?.addEventListener('click', () => {
                if (scrollArea && scrollArea.scrollLeft < scrollArea.scrollWidth - scrollArea.clientWidth) {
                    if (
                        scrollArea.scrollWidth - (scrollArea.scrollLeft + scrollArea.clientWidth) >
                        scrollArea?.clientWidth
                    ) {
                        scrollArea.scrollLeft = scrollArea.scrollLeft + scrollArea?.clientWidth
                    } else {
                        scrollArea.scrollLeft = scrollArea.scrollWidth - scrollArea.clientWidth
                    }
                }
            })
        }
    },
})
</script>

<style lang="postcss">
.misc-slider-component {
    @apply relative overflow-hidden;
    &__scroll-area {
        @apply flex w-full overflow-x-auto scroll-smooth;

        &.grid-variant {
            @apply gap-5 lg:grid lg:grid-cols-3 lg:overflow-hidden;
        }
    }
    &__navigation {
        @apply absolute top-1/2 hidden h-9 w-9 -translate-y-1/2 rounded-full border border-white bg-site-primary p-1 text-xl text-white;
        &.left {
            @apply left-4;
        }
        &.right {
            @apply right-4;
        }
    }

    &:hover {
        .misc-slider-component__navigation {
            @apply block;
            &.hide {
                @apply hidden;
            }
        }
    }

    &__navigation {
        &.grid-variant {
            @apply lg:hidden;
        }
    }
}
</style>
